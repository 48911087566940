import ticketDataModel from "@/static/ticketDataModel.json";

const callerTypes = {
  JULIE: {
    Contractor: "CONT",
    "Utility Company": "UTIL",
    Municipality: "MUNI",
    Government: "GOVT",
    "Public Service": "PUBL",
    Resident: "RESI",
    Utility: "UTIL",
    Other: "OTHR",
  },
  OUPS: {
    Homeowner: "HOME",
    Member: "MEMB",
    Contractor: "CONT",
    "Utility Company": "MEMB",
    Municipality: "MEMB",
    Government: "MEMB",
    Other: "MEMB",
  },
  UTAH: {
    "Professional Excavator": "E",
    Other: "O",
  },
  UNCC: {
    "Professional Excavator": "SPRE",
  },
  NCOCC: {
    Contractor: "CONT",
    Municipality: "MUNI",
    Government: "GOVT",
    Utility: "UTIL",
    Other: "OTHR",
  },
  VUPS: {
    Contractor: "CONT",
  },
  USAS: {
    Contractor: "CONT",
    City: "CITY",
    "USA/SC Member": "MEMR",
    Farmer: "FARM",
    Military: "MILT",
    CALTRANS: "CDOT",
    COUNTY: "CNTY",
    Other: "OTHR",
  },
};

export function getTicketData(
  ticketDetails,
  excavationDetails,
  features,
  user,
  company
) {
  const apiData = {
    data: null,
    user_id: user.user_id,
    company_id: company && company.company_id,
    center_id: ticketDetails.center_id,
    center: ticketDetails.center,
  };
  if (
    excavationDetails.associateProject &&
    Object.keys(excavationDetails.associateProject).length != 0
  )
    apiData.project_id = excavationDetails.associateProject.project_id;
  if (ticketDetails.center === "JULIE") {
    apiData.data = getTicketDataForJULIE(
      ticketDetails,
      excavationDetails,
      features,
      user,
      company
    );
  } else if (ticketDetails.center === "OUPS") {
    apiData.data = getTicketDataForOUPS(
      ticketDetails,
      excavationDetails,
      features,
      user,
      company
    );
  } else if (ticketDetails.center === "UTAH") {
    apiData.data = getTicketDataForUTAH(
      ticketDetails,
      excavationDetails,
      features,
      user,
      company
    );
  } else if (ticketDetails.center === "UNCC") {
    apiData.data = getTicketDataForUNCC(
      ticketDetails,
      excavationDetails,
      features,
      user,
      company
    );
  } else if (ticketDetails.center === "NCOCC") {
    apiData.data = getTicketDataForNCOCC(
      ticketDetails,
      excavationDetails,
      features,
      user,
      company
    );
  } else if (ticketDetails.center === "VUPS") {
    apiData.data = getTicketDataForVUPS(
      ticketDetails,
      excavationDetails,
      features,
      user,
      company
    );
  } else if (ticketDetails.center === "USAS") {
    apiData.data = getTicketDataForUSAS(
      ticketDetails,
      excavationDetails,
      features,
      user,
      company
    );
  }
  if (!apiData.data.shape.properties) {
    apiData.data.shape.properties = {};
  }
  return apiData;
}

function getTicketDataForJULIE(
  ticketDetails,
  excavationDetails,
  features,
  user,
  company
) {
  const { state, county, place, street, center, callerType } = ticketDetails;
  const {
    subdivision,
    depth_7ft,
    contact,
    contact_phone,
    location,
    done_for,
    work_type,
    comments,
    work_date,
    extent,
    survey1,
    survey2,
    boring,
  } = excavationDetails;

  const caller_type =
    user.type === "Homeowner" ? "HOME" : callerTypes[center][callerType];
  const streetDetails = getStreetDetails(ticketDetails);
  const shape = features[features.length - 1];
  const data = {
    ...ticketDataModel.julie,
    name: user.type === "Homeowner" ? user.name : company.name,
    phone: user.type === "Homeowner" ? user.phone : company.phone,
    address1: user.type === "Homeowner" ? user.address : company.address,
    city: user.type === "Homeowner" ? user.city : company.city,
    cstate: user.type === "Homeowner" ? user.state : company.state,
    zip: user.type === "Homeowner" ? user.zip : company.zip,
    email: user.email,
    comments: comments,
    contact_phone: contact_phone,
    contact: contact,
    county: county,
    extent: extent,
    location: location + ` \n ${ticketDetails?.streetLocation?.description}`,
    cross1: ticketDetails?.streetLocation?.cross1,
    place: place,
    state: state,
    street: street.substring(0, street.indexOf(",")) || street,
    subdivision: subdivision,
    work_date: work_date,
    done_for: done_for,
    work_type: work_type,
    boring: boring ? "Y" : "N",
    depth_7ft: depth_7ft ? "Y" : "N",
    survey1: survey1 ? "Y" : "N",
    survey2: survey2 ? "Y" : "NO",
    shape: shape,
    caller_type,
    caller: user.name,
    send_email: true,
    phone_ext: "",
    st_from_address: streetDetails.fradd || 0,
    st_to_address: streetDetails.toadd || 0,
    latitude: shape.geometry.coordinates[0][0][1],
    longitude: shape.geometry.coordinates[0][0][0],
  };

  return data;
}

function getTicketDataForOUPS(
  ticketDetails,
  excavationDetails,
  features,
  user,
  company
) {
  const { state, county, place, street, center, callerType } = ticketDetails;
  const {
    subdivision,
    contact,
    contact_phone,
    location,
    done_for,
    work_type,
    comments,
    work_date,
    white_paint,
    blasting,
    rr_or_highway,
    means_of_excavation,
    distance_from_road,
    direction_of_road,
    lot_number,
  } = excavationDetails;

  const caller_type =
    user.type === "Homeowner" ? "HOME" : callerTypes[center][callerType];
  const streetDetails = getStreetDetails(ticketDetails);
  const shape = features[features.length - 1];
  const data = {
    ...ticketDataModel.oups,
    name: user.type === "Homeowner" ? user.name : company.name,
    phone: user.type === "Homeowner" ? user.phone : company.phone,
    address1: user.type === "Homeowner" ? user.address : company.address,
    city: user.type === "Homeowner" ? user.city : company.city,
    zip: user.type === "Homeowner" ? user.zip : company.zip,
    cstate: user.type === "Homeowner" ? user.state : company.state,
    address2:
      user.type === "Homeowner"
        ? user.address
        : company.billing_address
        ? company.billing_address
        : company.address,
    email: user.email,
    state: state,
    county: county,
    place: place,
    street: street.substring(0, street.indexOf(",")) || street,
    subdivision: subdivision,
    location: `${location}, ${distance_from_road}, ${direction_of_road} \n ${ticketDetails?.streetLocation?.description}`,
    cross1: ticketDetails?.streetLocation?.cross1,
    cross2: ticketDetails?.streetLocation?.cross2,
    work_date: work_date,
    done_for: done_for,
    work_type: work_type,
    caller_type,
    caller: user.name,
    send_email: true,
    phone_ext: "",
    contact_phone: contact_phone,
    contact: contact,
    caller_phone: user.phone,
    comments: comments,
    shape: features[features.length - 1],
    white_paint: white_paint,
    blasting: blasting,
    rr_or_highway: rr_or_highway,
    means_of_excavation: means_of_excavation,
    lot_number: lot_number,
    st_from_address: streetDetails.fradd || 0,
    st_to_address: streetDetails.toadd || 0,
    latitude: shape.geometry.coordinates[0][0][1],
    longitude: shape.geometry.coordinates[0][0][0],
  };
  return data;
}

function getTicketDataForUTAH(
  ticketDetails,
  excavationDetails,
  features,
  user,
  company
) {
  const { state, county, place, street, center, callerType } = ticketDetails;
  const {
    subdivision,
    contact,
    contact_phone,
    done_for,
    work_type,
    extent, // Excavation Area of Lot
    excavationAreaTextBox,
    remarks,
    work_date,
    white_paint, //Dig site premarked with white paint or flags
    blasting,
    boring,
    excavation_area_in_road,
    direction_of_road, //Location on Property where the excavation will occur
    gate_code,
    lot, //Lot/Unit/Building Number
  } = excavationDetails;
  const streetDetails = getStreetDetails(ticketDetails);
  const shape = features[features.length - 1];
  const caller_type =
    user.type === "Homeowner" ? "HOME" : callerTypes[center][callerType];

  let locationTemp = "";
  locationTemp = getExcavationAreaOfLot(
    extent.toUpperCase(),
    excavationAreaTextBox
  );
  locationTemp += getExcavationAreaInRoad(excavation_area_in_road);
  locationTemp +=
    "\r\nLOCATION ON PROPERTY WHERE THE EXCAVATION WILL OCCUR IS " +
    direction_of_road.toUpperCase();
  if (white_paint)
    locationTemp += "\r\nDIG SITE BE PREMARKED WITH WHITE PAINT OR FLAGS";
  if (gate_code !== "") locationTemp += "\r\nGATE CODE: " + gate_code;

  // Use the extracted data to build the apiData.data object
  const data = {
    ...ticketDataModel.utah,
    name: user.type === "Homeowner" ? user.name : company.name,
    phone: user.type === "Homeowner" ? user.phone : company.phone,
    address1: user.type === "Homeowner" ? user.address : company.address,
    city: user.type === "Homeowner" ? user.city : company.city,
    zip: user.type === "Homeowner" ? user.zip : company.zip,
    cstate: user.type === "Homeowner" ? user.state : company.state,
    state,
    county,
    place,
    street: street.substring(0, street.indexOf(",")) || street,
    subdivision,
    location:
      locationTemp + ` \n ${ticketDetails?.streetLocation?.description}`,
    cross1: ticketDetails?.streetLocation?.cross1,
    cross2: ticketDetails?.streetLocation?.cross2,
    phone_ext: "",
    contact_phone_ext: "",
    contact_phone,
    contact,
    caller: user.name,
    caller_phone: user.phone,
    caller_type,
    email: user.email,
    work_type,
    work_date,
    remarks,
    done_for,
    shape: features[features.length - 1],
    blasting,
    dig_in_road: excavation_area_in_road === "None" ? false : true,
    boring: boring ? "Y" : "N",
    lot,
    cell: user.phone,
    st_from_address: streetDetails.fradd || 0,
    st_to_address: streetDetails.toadd || 0,
    latitude: shape.geometry.coordinates[0][0][1],
    longitude: shape.geometry.coordinates[0][0][0],
  };
  return data;
}

function getTicketDataForUNCC(
  ticketDetails,
  excavationDetails,
  features,
  user,
  company
) {
  const { state, county, place, street, center, callerType } = ticketDetails;
  const {
    blasting,
    boring,
    contact_phone,
    contact,
    done_for,
    leagal_notice,
    location,
    work_date,
    work_type,
  } = excavationDetails;

  const caller_type =
    user.type === "Homeowner" ? "HOME" : callerTypes[center][callerType];
  const streetDetails = getStreetDetails(ticketDetails);
  const shape = features[features.length - 1];
  const data = {
    ...ticketDataModel.uncc,
    name: user.type === "Homeowner" ? user.name : company.name,
    phone: user.type === "Homeowner" ? user.phone : company.phone,
    address1: user.type === "Homeowner" ? user.address : company.address,
    city: user.type === "Homeowner" ? user.city : company.city,
    zip: user.type === "Homeowner" ? user.zip : company.zip,
    cstate: user.type === "Homeowner" ? user.state : company.state,
    email: user.email,
    state: state,
    county: county,
    place: place,
    street: street.substring(0, street.indexOf(",")) || street,
    location: location + ` \n ${ticketDetails?.streetLocation?.description}`,
    cross1: ticketDetails?.streetLocation?.cross1,
    work_date: work_date,
    done_for: done_for,
    work_type: work_type,
    caller_type,
    caller: user.name,
    phone_ext: "",
    contact_phone: contact_phone,
    contact: contact,
    caller_phone: user.phone,
    shape: features[features.length - 1],
    blasting: blasting,
    boring,
    leagal_notice,
    st_from_address: streetDetails.fradd || 0,
    st_to_address: streetDetails.toadd || 0,
    latitude: shape.geometry.coordinates[0][0][1],
    longitude: shape.geometry.coordinates[0][0][0],
  };
  return data;
}

function getTicketDataForNCOCC(
  ticketDetails,
  excavationDetails,
  features,
  user,
  company
) {
  const { state, county, place, street, center, callerType } = ticketDetails;
  const {
    subdivision,
    contact,
    contact_phone,
    done_for,
    work_type,
    remarks,
    work_date,
    blasting,
    boring,
    location,
    locatorInstructions,
    gate_code,
    lots,
    directionToAddress,
    premarks,
    duration,
    rr,
  } = excavationDetails;
  const streetDetails = getStreetDetails(ticketDetails);
  const shape = features[features.length - 1];
  const caller_type =
    user.type === "Homeowner" ? "HOME" : callerTypes[center][callerType];

  let locationTemp = location;
  locationTemp += "\r\nGATE CODE:: " + gate_code;
  locationTemp += "\r\nLOCATE INSTRUCTIONS: " + locatorInstructions.join(", ");
  locationTemp += "\r\nDIRECTION TO ADDRESS: " + directionToAddress;
  locationTemp += "\r\nBORING TYPE:  " + directionToAddress;
  locationTemp += "\r\nDIRECTION TO ADDRESS: " + directionToAddress;
  if (boring) locationTemp += "\r\nBORING TYPE: Directional boring occur";
  if (premarks !== "") locationTemp += "\r\nPRE-MARKED WITH: " + premarks;

  // Use the extracted data to build the apiData.data object
  const data = {
    ...ticketDataModel.ncocc,
    name: user.type === "Homeowner" ? user.name : company.name,
    phone: user.type === "Homeowner" ? user.phone : company.phone,
    address1: user.type === "Homeowner" ? user.address : company.address,
    city: user.type === "Homeowner" ? user.city : company.city,
    zip: user.type === "Homeowner" ? user.zip : company.zip,
    cstate: user.type === "Homeowner" ? user.state : company.state,
    state,
    county,
    place,
    street: street.substring(0, street.indexOf(",")) || street,
    subdivision,
    location:
      locationTemp + ` \n ${ticketDetails?.streetLocation?.description}`,
    cross1: ticketDetails?.streetLocation?.cross1,
    cross2: ticketDetails?.streetLocation?.cross2,
    phone_ext: "",
    contact_phone,
    contact,
    caller: user.name,
    caller_type,
    email: user.email,
    work_type,
    work_date,
    remarks,
    done_for,
    shape: features[features.length - 1],
    blasting,
    boring,
    lots,
    cell: user.phone,
    premarks,
    duration,
    rr,
    st_from_address: streetDetails.fradd || 0,
    st_to_address: streetDetails.toadd || 0,
    latitude: shape.geometry.coordinates[0][0][1],
    longitude: shape.geometry.coordinates[0][0][0],
  };
  return data;
}

function getTicketDataForVUPS(
  ticketDetails,
  excavationDetails,
  features,
  user,
  company
) {
  const { state, county, place, street, center, callerType } = ticketDetails;
  const {
    subdivision,
    contact,
    contact_phone,
    done_for,
    work_type,
    remarks,
    blasting,
    boring,
    location,
    white_paint,
    comments,
  } = excavationDetails;
  const streetDetails = getStreetDetails(ticketDetails);
  const shape = features[features.length - 1];
  const caller_type =
    user.type === "Homeowner" ? "HOME" : callerTypes[center][callerType];
  const remarksTemp = remarks.join(", ");
  const workTypeTemp = work_type.toUpperCase();

  // Use the extracted data to build the apiData.data object
  const data = {
    ...ticketDataModel.vups,
    name: user.type === "Homeowner" ? user.name : company.name,
    phone: user.type === "Homeowner" ? user.phone : company.phone,
    address1: user.type === "Homeowner" ? user.address : company.address,
    city: user.type === "Homeowner" ? user.city : company.city,
    zip: user.type === "Homeowner" ? user.zip : company.zip,
    cstate: user.type === "Homeowner" ? user.state : company.state,
    state,
    county,
    place,
    street: street.substring(0, street.indexOf(",")) || street,
    subdivision,
    location: location + ` \n ${ticketDetails?.streetLocation?.description}`,
    cross1: ticketDetails?.streetLocation?.cross1,
    cross2: ticketDetails?.streetLocation?.cross2,
    caller_phone: user.phone,
    contact_phone,
    contact,
    caller: user.name,
    caller_type,
    email: user.email,
    work_type: workTypeTemp,
    remarks: remarksTemp,
    done_for,
    shape: features[features.length - 1],
    blasting,
    boring,
    white_paint,
    comments,
    st_from_address: streetDetails.fradd || 0,
    st_to_address: streetDetails.toadd || 0,
    latitude: shape.geometry.coordinates[0][0][1],
    longitude: shape.geometry.coordinates[0][0][0],
  };
  return data;
}
function getTicketDataForUSAS(
  ticketDetails,
  excavationDetails,
  features,
  user,
  company
) {
  const { state, county, place, street, center, callerType } = ticketDetails;
  const {
    subdivision,
    contact,
    contact_phone,
    contact_email,
    done_for,
    work_type,
    remarks,
    work_date,
    blasting,
    boring,
    location,
    white_paint,
    workingAtPole,
    poleNumberText,
    how_delineated,
    vacuum,
    pavement_only,
    excav_st_walk,
    permit,
  } = excavationDetails;
  const streetDetails = getStreetDetails(ticketDetails);
  const shape = features[features.length - 1];
  const caller_type =
    user.type === "Homeowner" ? "HOME" : callerTypes[center][callerType];
  const remarksTemp = remarks.join(", ");
  const workTypeTemp = work_type.toUpperCase();
  const howDelineatedTemp = how_delineated.map((item) => {
    return item == "White Paint" ? "WHITEPAINT" : item.toUpperCase().trim();
  });
  const locationData = location
    ? location.join(", ")
    : location + workingAtPole
    ? "\r\nPole Number: " + poleNumberText.toUpperCase()
    : " ";
  // Use the extracted data to build the apiData.data object
  const data = {
    ...ticketDataModel.usas,
    name: user.type === "Homeowner" ? user.name : company.name,
    phone: user.type === "Homeowner" ? user.phone : company.phone,
    address1: user.type === "Homeowner" ? user.address : company.address,
    city: user.type === "Homeowner" ? user.city : company.city,
    zip: user.type === "Homeowner" ? user.zip : company.zip,
    cstate: user.type === "Homeowner" ? user.state : company.state,
    state,
    county,
    place,
    street: street.substring(0, street.indexOf(",")) || street,
    subdivision,
    location:
      locationData.length > 1
        ? locationData + ` \n ${ticketDetails?.streetLocation?.description}`
        : undefined,
    phone_ext: "",
    contact_phone,
    contact,
    contact_email,
    caller: user.name,
    caller_type,
    email: user.email,
    eml_confirm: true,
    work_type: workTypeTemp,
    work_date,
    remarks: remarksTemp,
    done_for,
    shape: features[features.length - 1],
    blasting,
    boring,
    permit,
    white_paint,
    comments: "",
    cross1: ticketDetails?.streetLocation?.cross1,
    cross2: ticketDetails?.streetLocation?.cross2,
    vacuum: vacuum ? "Y" : "N",
    pavement_only,
    excav_st_walk,
    how_delineated: howDelineatedTemp,
    project_id: "",
    project_name: "",
    sms_confirm: false,
    work_order: "",
    st_from_address: streetDetails.fradd || 0,
    st_to_address: streetDetails.toadd || 0,
    latitude: shape.geometry.coordinates[0][0][1],
    longitude: shape.geometry.coordinates[0][0][0],
    caller_gps:
      shape.geometry.coordinates[0][0][1] +
      "/" +
      shape.geometry.coordinates[0][0][0],
  };
  return data;
}
function getExcavationAreaOfLot(extent, excavationAreaTextBox) {
  extent = extent.toUpperCase();
  if (
    extent == "ENTIRE LOT" ||
    extent == "FRONT OF LOT" ||
    extent == "REAR OF LOT"
  ) {
    return "STAKE " + extent;
  } else if (excavationAreaTextBox) {
    return "OTHER EXCAVATION AREA OF PROPERTY: " + extent;
  } else {
    return extent;
  }
}

function getExcavationAreaInRoad(excavation_area_in_road) {
  excavation_area_in_road = excavation_area_in_road.toUpperCase();
  switch (excavation_area_in_road) {
    case "SHORT":
      return "\r\nSTAKE SHORT SIDE CROSSING THE ROAD";
    case "LONG":
      return "\r\nSTAKE LONG SIDE CROSSING THE ROAD";
    case "NONE":
      return "\r\nNOTHING WILL BE MARKED IN THE ROAD";
    case "OTHER":
      return "\r\nEXCAVATION AREA IN ROAD: " + excavation_area_in_road;
  }
}

function getStreetDetails(ticketDetails) {
  let selectedAddress = {};
  if (ticketDetails.lookupType === "street") {
    selectedAddress =
      ticketDetails.streetSearchResults.find((item) => {
        return item.address === ticketDetails.street;
      }) || {};
  }
  if (ticketDetails.lookupType === "cross") {
    selectedAddress =
      ticketDetails.streetSearchResults.find((item) => {
        return item.address === ticketDetails.street;
      }) || {};
  }
  if (ticketDetails.lookupType === "both") {
    selectedAddress = ticketDetails.streetSearchResults || {};
  }
  return selectedAddress;
}
